import './../App.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';

export const Main = () => {
    // const sUrl = 'http://localhost:5000';
    const sUrl = 'https://api.readysteadyportal.com';
    const [token, setToken] = useState();
    const [institutions, setInstitutions] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [banks, setBanks] = useState([]);
    const { dealid } = useParams();

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredBanks = banks.filter(bank =>
        bank.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // console.log(dealid);

    //step 1
    const fetchToken = async () => {
        const url = `${sUrl}/gocardless-token`;
        try {
            const response = await fetch(url, {
                method: 'GET'
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const responseData = await response.json();
            localStorage.setItem('bankToken', responseData?.access)
            setToken(responseData?.access);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    //step 2
    const fetchInstitutions = async () => {
        try {
            setLoading(true);
            const response = await axios.post(`${sUrl}/gocardless-institutions`, {
                token
            });
            setBanks(response.data);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    // sandbox SANDBOXFINANCE_SFIN0000

    useEffect(() => {
        fetchToken();
    }, []);

    useEffect(() => {
        fetchInstitutions();
    }, [token])

    //step 3
    const fetchEndUserAgreement = async (bankId) => {
        try {
            setLoading(true);
            const response = await axios.post(`${sUrl}/gocardless-enduser-agreements`, {
                token: token, // Include the token directly, not in a 'body' property
                institution_id: bankId
            });

            // Process the response as needed
            const responseData = response.data;
            console.log('Response data:', responseData);
            fetchRequisitions(responseData.id, bankId);
        } catch (error) {
            console.error('Error fetching end user agreement:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    //step 4
    const fetchRequisitions = async (aggrementId, bankId) => {
        try {
            setLoading(true);
            const response = await axios.post(`${sUrl}/gocardless-requisitions`, {
                token,
                bankId,
                dealid,
                aggrementId
            });
            const to = response.data.link;
            // console.log(response.data);
            window.location.href = to
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="w-full h-screen flex items-center justify-center">
            <div className="modal-box bg-white p-0">
                <div className='sticky top-[-2px] text-center bg-white pt-3 pb-5 px-8'>
                    <img className='w-[250px] mx-auto  mt-5' src='https://www.sedgefunding.co.uk/wp-content/uploads/2023/08/7fb9a971-c7df-4dfa-a0f3-1c572ab595f6.png' />
                    <h3 className="font-bold text-lg mb-6">Connect Your Bank With Us!</h3>
                    <input
                        type="text"
                        placeholder="Search for a bank..."
                        value={searchTerm}
                        onChange={handleSearch}
                        className='input input-bordered w-full'
                    />
                </div>
                <div className='p-8 pt-3 overflow-y-auto h-[calc(100vh-10%)]'>
                    {filteredBanks.map(bank => (
                        // <div onClick={() => fetchRequisitions(bank?.id)} key={bank.id} className='bg-gray-100 mb-4 p-5 flex items-center rounded-lg cursor-pointer'>
                        <button onClick={(e) => fetchEndUserAgreement(bank.id)} key={bank.id} className='bg-gray-100 mb-4 p-5 flex items-center rounded-lg cursor-pointer w-full'>
                            {/* <Link to={`/enduser-aggrement?dealId=${dealid}&bankId=${bank?.id}&bankName=${bank.name}`} key={bank.id} className='bg-gray-100 mb-4 p-5 flex items-center rounded-lg cursor-pointer'> */}
                            <img src={bank.logo} alt={bank.name} className='h-10 mr-5' />
                            <p>{bank.name}</p>
                        </button>
                    ))}
                </div>
            </div>
        </div>
    )
}
