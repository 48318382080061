import React from 'react'
import { useLocation, useParams } from 'react-router-dom'

export const Success = () => {
    // const sUrl = 'http://localhost:5000';
    const sUrl = 'https://api.readysteadyportal.com';
    const { dealid } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const ref = queryParams.get('ref');
    const error = queryParams.get('error');

    console.log(useParams());

    const updateLoanData = () => {
        const updatedLoanDataObject = {
            'open-banking-ref': ref,
            'status': "Pre-Underwriting",
        };

        fetch(`${sUrl}/update-open-banking/${dealid}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatedLoanDataObject)
        })
            .then(response => response.json())
            .then(result => {
                console.log('done');
                window.location.href = 'https://www.sedgefunding.co.uk/thank-you/';
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }


    if(ref.length!==0 && !error){
        updateLoanData();
    } else {
        window.location.href = 'https://www.sedgefunding.co.uk/sorry-try-again/';
    }



    return (
        <div className='w-full h-screen flex items-center justify-center'><div className="w-12 h-12 rounded-full animate-spin border border-solid border-black border-t-transparent shadow-md"></div></div>
    )
}
