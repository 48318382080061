import { createBrowserRouter } from "react-router-dom";
import { Main } from "../Pages/Main";
import { Success } from "../Pages/Success";

const router = createBrowserRouter([
    // {
    //     path: '/',
    //     element: <Main />
    // },
    {
        path: '/:dealid',
        element: <Main />
    },
    // {
    //     path: '/enduser-aggrement',
    //     element: <Aggrement />
    // },
    {
        path: '/success/:dealid',
        element: <Success />
    },

    // {
    //     path: '/customer',
    //     element: <Customer></Customer>,
    //     children: [
    //         {
    //             path: '/customer/dashboard',
    //             element: <PrivateRoute><CustomerDashboard></CustomerDashboard></PrivateRoute>
    //         },
    //         {
    //             path: '/customer/profile',
    //             element: <PrivateRoute><CustomerProfile></CustomerProfile></PrivateRoute>
    //         }
    //     ]
    // },

])

export { router };